<template>
    <ion-card class="ion-text-center">  
            
        <ion-img :src="'/assets/' + item.image" :alt="item.name" :router-link="`/tabs/menu/${item.id}?addtoorder=1#customize`"/>
        
        <ion-card-subtitle :router-link="`/tabs/menu/${item.id}?addtoorder=1#customize`" style="margin-top: 10px;">
             {{item.name}}
        </ion-card-subtitle>
        <br>
    </ion-card>
</template>

<script>
//import { IonItem, IonImg, IonLabel, IonButton, IonAvatar, IonIcon} from '@ionic/vue';

export default {
    props: ["item"],
    components: {
    },
}
</script>