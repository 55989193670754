<template>
  <base-layout title="Menu">
      <ion-grid>
          <ion-row class="ion-justify-content-start">
              <ion-col  size-md="4"  size-lg="3" size-sm="6" size-xs="6"
              v-for="item in menuItems" 
              :key="item.id">           
          <menu-list-item  
              :item="item"
              >
          </menu-list-item>
          </ion-col>
        </ion-row>
      </ion-grid>
  </base-layout>
</template>

<script>
//import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
//import { IonList  } from '@ionic/vue';
import MenuListItem from '../components/MenuListItem.vue';
export default  {
  name: 'Menu',
  components: { MenuListItem },
  data() {
    return {
      
    };
  },
  computed: {
     menuItems() {
      return this.$store.getters.menuItems
    }
  },
  mounted() {
    if (this.stockItems == null) {
      this.$store.dispatch('loadStock');
    }
  }  
  
}
</script>